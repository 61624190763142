import {
  // useGetInstallmentsByContract,
  usePayInstallmentsByContract
} from '@volvo-apps/shared/core/hooks/contracts'
import ContractInstallmentDTO from '@volvo-apps/shared/models/ContractInstallmentDTO'

import React, { useMemo } from 'react'

import { Box, Typography } from '@mui/material'
// import { borderColor } from '@mui/system'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'

import { Form, Modal } from 'components'
import { DataGrid } from 'components/DataGrid'
import { FlexBox } from 'components/FlexBox'
import InvoicesErrorModal from 'components/InvoicesErrorModal/InvoicesErrorModal'
// import { NoInfo } from 'components/NoInfo'

import { useGetAllHoliday } from 'hooks/holidays/useGetAllHoliday'

import Colors from 'constants/Colors'

// import { BillPaymentModal } from '../BillPaymentModal'

import {
  // dateWithoutTimezone,
  getDateKeyIgnoringYearAndTimezone
} from 'common/utils/date'
import { useLanguage } from 'context/Language'

// import { LatestAgreementsStep } from '../DischargeContractModal/LatestAgreementsStep'
import { handleInvoiceError } from '../handleInvoiceError'
import { DiscountModal } from './DiscountModal'
// import { parcelsColumns } from './parcelsColumns'
import { AdvancePaymentModalData, getSchema } from './schema'
// import { subtotalColumns } from './subtotalColumns'
import { getColumns } from './tableColumns'
import { totalPreviewColumns } from './totalPreviewColumns'

type AdvancePaymentModalProps = {
  open: boolean
  handleClose: () => void
  contractNumber: string
  financeType: string
  documentNumber: string
  hasRecentAgreements?: boolean
}

/**
 *
 */
export type InstallmentsValues = {
  /**
   *
   */
  currentValue: number
  /**
   */
  discounts: number
  /**
   */
  value: number
}

/**
 *
 * @returns TSX
 * @param props the modal props
 */
const AdvancePaymentModal = (props: AdvancePaymentModalProps) => {
  const {
    open,
    handleClose,
    contractNumber,
    financeType,
    documentNumber
    // hasRecentAgreements = false
  } = props

  const queryClient = useQueryClient()
  const { t } = useLanguage()

  const [installmentsValues, setInstallmentsValues] =
    React.useState<InstallmentsValues>({
      currentValue: 0,
      discounts: 0,
      value: 0
    })
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
  const [selectedInstallments, setSelectedInstallments] = React.useState<
    number[]
  >([])
  const [isAllInstallmentsSelected, setIsAllInstallmentsSelected] =
    React.useState(false)
  const [activeStep, setActiveStep] = React.useState(0)
  const { holidayList } = useGetAllHoliday({})

  const [dueDate, setDueDate] = React.useState<Date>(new Date())

  const data = queryClient.getQueryData([
    'getAllInstallmentsByContract',
    contractNumber,
    documentNumber
  ]) as ContractInstallmentDTO | undefined

  const handleCloseModal = () => {
    setErrorMessage(null)
  }
  const holidayMap = useMemo(() => {
    const map: { [key: string]: boolean } = {}
    holidayList?.forEach((item) => {
      const key = getDateKeyIgnoringYearAndTimezone(new Date(item.date))
      map[key] = true
    })
    return map
  }, [holidayList])

  const methods = useForm<AdvancePaymentModalData>({
    mode: 'all',
    resolver: getSchema(holidayMap),
    defaultValues: {
      dueDate: new Date()
    }
  })

  const { isLoading: isPaymentLoading } = usePayInstallmentsByContract({
    contractNumber,
    dueDate,
    documentNumber,
    installmentsToPay: selectedInstallments,
    financeType,
    enabled: false,
    retry: 0,
    onSuccess: handleClose,
    onError: async (error) => {
      handleInvoiceError(error, (Message) => {
        setErrorMessage(Message)
      })
    }
  })

  // const handleGenerateBill = () => {
  //   refetch()
  // }

  // const shouldDisableDate = (date: Date): boolean => {
  //   const currentDate = dateWithoutTimezone(new Date(date))
  //   const today = dateWithoutTimezone(new Date())

  //   if (currentDate < today) {
  //     return true
  //   }

  //   if (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
  //     return true
  //   }

  //   const dateKey = getDateKeyIgnoringYearAndTimezone(currentDate)
  //   return holidayMap[dateKey] || false
  // }

  const nextStep = () => {
    setActiveStep(activeStep + 1)
    document.getElementById('modal-container')?.scrollIntoView({
      block: 'start'
    })
  }
  // const goBack = () => {
  //   setActiveStep(activeStep - 1)
  // }

  const steps = {
    0: (
      <FlexBox width="100%" flexDirection="column" gap={2}>
        <Typography color={Colors.light.greys.GREY_600} mb={2}>
          {t('financial.contracts.modal.selectParcel')}
        </Typography>
        <DataGrid
          onSelectionModelChange={(selectionModel) => {
            let currentValue = 0
            let discounts = 0
            let value = 0
            const installments: Array<number> = []

            selectionModel.forEach((rowId) => {
              const installment = data?.installmentsArray.find(
                (installment) => installment.installmentNo === rowId
              )

              if (installment) {
                installments.push(installment.installmentNo)
                currentValue += installment.installmentCurrentValue ?? 0
                discounts += installment.installmentDiscountValue ?? 0
                value += installment.installmentValue ?? 0
              }
            })

            setIsAllInstallmentsSelected(
              installments.length === data?.installmentsArray.length
            )

            setDueDate(new Date())
            setSelectedInstallments(installments)
            setInstallmentsValues({
              currentValue,
              discounts,
              value
            })
          }}
          type="checkbox"
          columns={getColumns(data?.totalInstallments)}
          rows={data?.installmentsArray ?? []}
          hideFooter
          getRowId={(row) => row.installmentNo}
        />
        <FlexBox
          position="sticky"
          bottom={0}
          width="100%"
          sx={{
            backgroundColor: Colors.light.background
          }}
        >
          {data && (
            <DataGrid
              type="darkCells"
              columns={totalPreviewColumns}
              rows={[
                {
                  id: 1,
                  total: installmentsValues.value
                }
              ]}
              hideFooter
            />
          )}
        </FlexBox>
      </FlexBox>
    ),
    1: (
      <DiscountModal
        selectedInstallments={selectedInstallments}
        installmentsValues={installmentsValues}
        data={data}
        isAllInstallmentsSelected={isAllInstallmentsSelected}
      />
    )
    // 1: (
    //   <LatestAgreementsStep
    //     handleBack={goBack}
    //     handleCancel={handleClose}
    //     handleNext={nextStep}
    //     contractNumber={contractNumber}
    //     documentNumber={documentNumber}
    //   />
    // ),
    // 2: (
    //   <FlexBox width="100%" flexDirection="column" gap={2}>
    //     <Typography color={Colors.light.greys.GREY_600} mb={1}>
    //       {t('financial.contracts.modal.seeParcels')}
    //     </Typography>
    //     <DataGrid
    //       type="darkCells"
    //       columns={parcelsColumns}
    //       rows={
    //         data
    //           ? [
    //               {
    //                 id: 1,
    //                 parcels: selectedInstallments
    //                   .sort()
    //                   .join(', ')
    //                   .replace(/,([^,]*)$/, ' e$1')
    //               }
    //             ]
    //           : []
    //       }
    //       hideFooter
    //     />
    //     <DataGrid
    //       type="darkCells"
    //       columns={subtotalColumns}
    //       rows={
    //         data
    //           ? [
    //               {
    //                 id: 1,
    //                 subtotal: installmentsValues.value,
    //                 discount: installmentsValues.discounts,
    //                 total: installmentsValues.currentTotalValue
    //               }
    //             ]
    //           : []
    //       }
    //       hideFooter
    //     />
    //     <Typography color={Colors.light.greys.GREY_600} mt={1} mb={1}>
    //       {t('financial.contracts.modal.selectDueDate')}
    //     </Typography>
    //     <Datepicker
    //       label={t('financial.contracts.modal.selectDate')}
    //       name="dueDate"
    //       onChange={(e) => {
    //         setDueDate(e || new Date())
    //       }}
    //       shouldDisableDate={shouldDisableDate}
    //     />
    //   </FlexBox>
    // )
  }

  // const stepContent = hasRecentAgreements
  //   ? steps
  //   : {
  //       0: steps[0],
  //       1: steps[2]
  //     }

  const actions = {
    0: [
      {
        children: t('financial.contracts.modal.calculateDiscounts'),
        onClick: () => {
          nextStep()
        },
        variant: 'contained',
        fullWidth: true,
        loading: isPaymentLoading,
        sx: { mt: 2, pr: 1.25 },
        disabled: selectedInstallments.length === 0 || isPaymentLoading
      },
      {
        children: t('financial.contracts.modal.cancel'),
        onClick: handleClose,
        fullWidth: true
      }
    ],
    1: [
      {
        children: t('financial.contracts.modal.closeModal'),
        onClick: handleClose,
        variant: 'outlined',
        fullWidth: true,
        sx: {
          mt: 2,
          color: Colors.light.interactActive,
          borderColor: Colors.light.interactActive
        }
      }
    ]
    // 1: [],
    // 2: [
    //   // {
    //   //   children: t('financial.contracts.modal.payWithPix'),
    //   //   onClick: handleClose,
    //   //   variant: 'contained',
    //   //   fullWidth: true,
    //   //   sx: { mt: 2 }
    //   // },
    //   {
    //     children: t('financial.contracts.modal.generateBill'),
    //     onClick: () => {
    //       // setModalOpen(true)
    //       handleGenerateBill()
    //     },
    //     variant: 'contained',
    //     fullWidth: true,
    //     loading: isPaymentLoading,
    //     sx: { mt: 2 }
    //     // disabled: isPaymentLoading
    //   },
    //   {
    //     children: t('financial.contracts.modal.back'),
    //     onClick: goBack,
    //     fullWidth: true
    //   }
    // ]
  }

  // const stepActions = hasRecentAgreements
  //   ? actions
  //   : {
  //       0: actions[0],
  //       1: actions[2]
  //     }

  // const isLatestAgreementsStep = hasRecentAgreements && activeStep === 1

  const modalTitle = useMemo(() => {
    // if (isLatestAgreementsStep)
    //   return t('financial.contracts.modal.installment.title')
    // if (activeStep === 1) return t('financial.contracts.modal.advanceDiscounts')
    return t('financial.contracts.modal.advancePayment')
  }, [t])

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        showCloseButton
        disableBackdropClick
        title={
          <Typography
            fontSize={32}
            fontWeight={700}
            color={
              Colors.light.text
              // isLatestAgreementsStep ? Colors.light.error : Colors.light.text
            }
          >
            {modalTitle}
          </Typography>
        }
        PaperProps={{
          sx: { p: 4, borderRadius: 2, maxWidth: 1000, width: 848 }
        }}
        actionsDirection="column"
        actionsGap={2}
        actions={actions[activeStep]}
        dialogContentSx={{
          paddingBottom: 0,
          paddingRight: 1.25
        }}
        content={
          <Form methods={methods}>
            <Box
              id="modal-container"
              sx={{
                width: '100%'
              }}
            >
              {steps[activeStep]}
            </Box>
            {errorMessage && (
              <InvoicesErrorModal
                open={!!errorMessage}
                text={errorMessage}
                setClosed={handleCloseModal}
              />
            )}
          </Form>
        }
      />

      {/* {openModal && (
        <BillPaymentModal open={openModal} handleClose={handleCloseModal} />
      )} */}
    </>
  )
}

export default AdvancePaymentModal
