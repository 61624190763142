import { getAllHolidays } from '@volvo-apps/shared/core/actions/Holiday'
import HolidayDTO from '@volvo-apps/shared/models/HolidayDTO'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useGetAllHolidayParams = {
  onSuccess?: (holiday: HolidayDTO[]) => void
}

/**
 * Hook to get all holiday
 *
 * @param params params
 * @returns holiday, isLoading, refetch
 */
export const useGetAllHoliday = (params: useGetAllHolidayParams) => {
  const { onSuccess } = params
  const {
    data: holidayList,
    isLoading,
    refetch
  } = useQuery('getAllHoliday', {
    queryFn: () => getAllHolidays(),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { holidayList, isLoading, refetch }
}
