import { useImportBlockedContracts } from '@volvo-apps/shared/core/hooks/finance/useImportBlockedContracts'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { FlexBox } from 'components/FlexBox'
import { UploadContainer } from 'components/UploadContainer'

import { RegistersRoutesEnum } from 'routes/cms/routesEnums'

import Colors from 'constants/Colors'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

/**
 * Blocked Contracts Create
 *
 * @returns TSX
 */
function Create() {
  const navigate = useNavigate()
  const { t } = useLanguage()

  const { mutate, isLoading } = useImportBlockedContracts({
    onSuccess: () => {
      toast.success(t('cms.manualBlock.create.success'))
      navigate(RegistersRoutesEnum.MANUAL_BLOCK)
    },
    onError: handleErrorFeedback
  })

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const reader = new FileReader()
      const file = e.target.files[0]

      reader.readAsArrayBuffer(file)

      reader.onloadend = async () => {
        if (reader.result) {
          const body = new FormData()
          body.append('file', new Blob([reader.result]), file.name)

          mutate({ formData: body })
        }
      }
    }
  }

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.manualBlock.create.title')}
      </Typography>

      <FlexBox
        sx={(t) => ({
          maxWidth: t.breakpoints.values.sm,
          flexDirection: 'column'
        })}
      >
        <UploadContainer
          multiple={false}
          description={t('cms.manualBlock.form.upload')}
          onChange={onChange}
          loading={isLoading}
          accept=".csv"
        />
      </FlexBox>
    </CmsChildrenWithHeader>
  )
}

export default Create
export { Create }
