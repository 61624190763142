import { useMemo } from 'react'

import {
  LeadsRoutesEnum,
  ParametersRoutesEnum,
  ReportsRoutesEnum,
  RegistersRoutesEnum,
  SurveyRoutesEnum,
  UserSettingsRoutesEnum
} from 'routes/cms/routesEnums'

import { useUser } from 'context/User'
import { RoleEnum } from 'types/roles'

/**
 * Hook to check if the user has a specific role
 *
 * @returns hasRole - Function to check if the user has a specific role
 */
const useCmsRoles = () => {
  const { roles = [] } = useUser()

  const rolesHashMap = useMemo(() => {
    const newRoles = typeof roles === 'string' ? [roles] : roles

    return newRoles.reduce((acc, role) => {
      acc[role] = true
      return acc
    }, {})
  }, [roles])

  const hasRole = (role: string) => rolesHashMap[role]

  const showMenuByPage = {
    [LeadsRoutesEnum.LEADS_VIEW]:
      rolesHashMap[RoleEnum.LEADS_VISUALIZAR_EXPORTAR],
    [LeadsRoutesEnum.LEADS_AUTOMATION_LIST]:
      rolesHashMap[RoleEnum.LEADS_AUTOMACAO_RECEBIMENTO],
    [RegistersRoutesEnum.BANNERS]: rolesHashMap[RoleEnum.CADASTRO_BANNERS],
    [RegistersRoutesEnum.FAQ]: rolesHashMap[RoleEnum.CADASTRO_FAQ],
    [RegistersRoutesEnum.HOLIDAYS]: rolesHashMap[RoleEnum.CADASTRO_FERIADOS],
    [RegistersRoutesEnum.PUSH_NOTIFICATIONS]:
      rolesHashMap[RoleEnum.CADASTRO_NOTIFICACOES_PUSH],
    [RegistersRoutesEnum.CONSORTIUM_RESOURCES]:
      rolesHashMap[RoleEnum.CADASTRO_RECURSOS_ENCERRADOS],
    [RegistersRoutesEnum.TERMS]: rolesHashMap[RoleEnum.CADASTRO_TERMOS],
    [RegistersRoutesEnum.RENTAL_CONTRACTS]:
      rolesHashMap[RoleEnum.CADASTRO_CONTRATOS_ALUGUEL],
    [RegistersRoutesEnum.MANUAL_BLOCK]:
      rolesHashMap[RoleEnum.BLOQUEIO_CONTRATO],
    [ReportsRoutesEnum.USER_REPORTS_FORM]:
      rolesHashMap[RoleEnum.RELATORIOS_USUARIOS],
    [ReportsRoutesEnum.USER_SEARCH]:
      rolesHashMap[RoleEnum.RELATORIOS_CONSULTA_USUARIO],
    [ParametersRoutesEnum.FINANCIAL_PRODUCTS]:
      rolesHashMap[RoleEnum.PARAMETROS_PRODUTOS_FINANCEIROS],
    [ParametersRoutesEnum.PRODUCT_LINES]:
      rolesHashMap[RoleEnum.PARAMETROS_LINHA_PRODUTO],
    [ParametersRoutesEnum.SIMULATION_PARAMS]:
      rolesHashMap[RoleEnum.PARAMETROS_SIMULACAO],
    [ParametersRoutesEnum.VEHICLES]: rolesHashMap[RoleEnum.PARAMETROS_VEICULOS],
    [SurveyRoutesEnum.SURVEY_NPS]: rolesHashMap[RoleEnum.PESQUISAS_NPS],
    [UserSettingsRoutesEnum.SCR_MENU]:
      rolesHashMap[RoleEnum.USUARIO_CONFIG_CONSULTA],
    [UserSettingsRoutesEnum.USERS_ACCESS_LIST]:
      rolesHashMap[RoleEnum.USUARIO_CONFIG_ACESSO_CMS],
    [UserSettingsRoutesEnum.RESET_EMAIL_AND_PASSWORD]:
      rolesHashMap[RoleEnum.USUARIO_CONFIG_REDEFINIR_SENHA_EMAIL],
    [UserSettingsRoutesEnum.LINK_USERS_LIST]:
      rolesHashMap[RoleEnum.USUARIO_CONFIG_VINCULAR],
    [UserSettingsRoutesEnum.ROLES_LIST]:
      rolesHashMap[RoleEnum.USUARIO_CONFIG_FUNCOES],
    [UserSettingsRoutesEnum.USER_DOMAINS]:
      rolesHashMap[RoleEnum.USUARIO_CONFIG_DOMINIO]
  }

  return {
    hasRole,
    showMenuByPage
  }
}

export default useCmsRoles
export { useCmsRoles }
