import React from 'react'

import { Box } from '@mui/material'
import { useNavigate } from 'react-router'

import { List, ListItemProps } from 'components'
import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CmsMenuHeader } from 'components/CmsMenuHeader'

import { RegistersRoutesEnum } from 'routes/cms/routesEnums'

import useCmsRoles from 'hooks/cms/useCmsRoles'

import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
function Menu() {
  const navigate = useNavigate()
  const { showMenuByPage } = useCmsRoles()
  const { t } = useLanguage()

  const menuItems: ListItemProps[] = [
    {
      primary: t('cms.dashboard.tabs.banners'),
      onClick: () => navigate(RegistersRoutesEnum.BANNERS),
      hide: !showMenuByPage[RegistersRoutesEnum.BANNERS]
    },
    {
      primary: t('cms.dashboard.tabs.faq'),
      onClick: () => navigate(RegistersRoutesEnum.FAQ),
      hide: !showMenuByPage[RegistersRoutesEnum.FAQ]
    },
    {
      primary: t('cms.dashboard.tabs.holidays'),
      onClick: () => navigate(RegistersRoutesEnum.HOLIDAYS),
      hide: !showMenuByPage[RegistersRoutesEnum.HOLIDAYS]
    },
    {
      primary: t('cms.dashboard.tabs.pushNotifications'),
      onClick: () => navigate(RegistersRoutesEnum.PUSH_NOTIFICATIONS),
      hide: !showMenuByPage[RegistersRoutesEnum.PUSH_NOTIFICATIONS]
    },
    {
      primary: t('cms.dashboard.tabs.consortiumResources'),
      onClick: () => navigate(RegistersRoutesEnum.CONSORTIUM_RESOURCES),
      hide: !showMenuByPage[RegistersRoutesEnum.CONSORTIUM_RESOURCES]
    },
    {
      primary: t('cms.dashboard.tabs.termsAndConditions'),
      onClick: () => navigate(RegistersRoutesEnum.TERMS),
      hide: !showMenuByPage[RegistersRoutesEnum.TERMS]
    },
    {
      primary: t('cms.dashboard.tabs.rentalContracts'),
      onClick: () => navigate(RegistersRoutesEnum.RENTAL_CONTRACTS),
      hide: !showMenuByPage[RegistersRoutesEnum.RENTAL_CONTRACTS]
    },
    {
      primary: t('cms.dashboard.tabs.manualBlock'),
      onClick: () => navigate(RegistersRoutesEnum.MANUAL_BLOCK),
      hide: !showMenuByPage[RegistersRoutesEnum.MANUAL_BLOCK]
    }
  ]

  return (
    <CmsChildrenWithHeader>
      <CmsMenuHeader
        titleText={t('cms.dashboard.tabs.registers')}
        subtitleText={t('cms.registers.subtitle')}
      />
      <Box mt={2} width={(t) => t.breakpoints.values.md}>
        <List items={menuItems} noPadding />
      </Box>
    </CmsChildrenWithHeader>
  )
}

export default Menu
export { Menu }
