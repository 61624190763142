import { AxiosError } from 'axios'
import { t } from 'i18next'
import { toast } from 'react-toastify'

import {
  handleErrorFeedback,
  handleErrorFeedbackIgnoreNotFound
} from 'common/utils/error'

/**
 *
 * @param error - The error that occurred during the invoice operation
 * @param callback - Callback function to handle the error message
 */
export const handleInvoiceError = async (
  error: unknown,
  callback: (Message: string) => void
) => {
  if (error instanceof AxiosError) {
    try {
      const err = await error.response?.data.text()
      const errorJson = JSON.parse(err) as {
        Message: string
      }

      if (errorJson) {
        const messageJson = JSON.parse(errorJson.Message)

        if (messageJson.TypeMessage) {
          const { TypeMessage, Message } = messageJson as {
            TypeMessage: string
            Message: string
          }

          if (TypeMessage === 'RulesError') {
            callback(Message)
            return
          }
        }
      }
      handleErrorFeedback(error)
    } catch (jsonError) {
      handleErrorFeedbackIgnoreNotFound(error, () => {
        toast.error(t('financial.contracts.modal.error.generateBill'))
      })
    }
  }
}
