import { useMutation } from 'react-query'
import { HookQueryParams } from '../../../common/utils/types'
import { importFileAsync } from '../../actions/Finance'

type useImportBlockedContractsParams = HookQueryParams<string>

/**
 * Hook to import blocked contracts
 *
 * @param params params
 * @returns mutate, data, isLoading and mutateAsync
 */
export const useImportBlockedContracts = (
  params: useImportBlockedContractsParams = {}
) => {
  const { onSuccess } = params
  const { mutate, isLoading, data, mutateAsync } = useMutation(
    'importBlockedContracts',
    {
      mutationFn: ({ formData }: { formData: FormData }) =>
        importFileAsync(formData),
      onSuccess
    }
  )

  return { mutate, data, isLoading, mutateAsync }
}
