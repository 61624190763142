import { GridColDef } from '@mui/x-data-grid'

/**
 *
 * @returns TSX
 */
export const parcelsColumns: GridColDef[] = [
  {
    field: 'parcels',
    headerName: 'financial.contracts.modal.columns.parcels',
    sortable: false,
    flex: 1
  }
]
