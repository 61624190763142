import { useMutation } from 'react-query'
import { deleteImportBlockedContract } from '../../actions/Finance'

type useDeleteImportBlockedContractParams = {
  onSuccess?: () => void
  onError?: (err: unknown) => void
}

/**
 * Hook to delete one import blocked contract
 *
 * @param params params
 * @returns mutate, data, isLoading
 */
export const useDeleteImportBlockedContract = (
  params: useDeleteImportBlockedContractParams = {}
) => {
  const { onSuccess, onError } = params
  const { mutate, isLoading, data } = useMutation(
    'deleteImportBlockedContract',
    {
      mutationFn: ({ id }: { id: number }) => deleteImportBlockedContract(id),
      onSuccess,
      onError
    }
  )

  return { mutate, data, isLoading }
}
