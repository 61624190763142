import 'reflect-metadata'
import { jsonProperty, Serializable } from 'ts-serializable'
import ContractDTO from './ContractDTO'
import { SalesVehicleDTO } from './SalesVehicleDTO'
import { SalesInvoiceDTO } from './SalesInvoiceDTO'
import RentalContractDTO from './RentalContractDTO'

/**
 * The response of SalesVehicleData
 */
class SalesVehicleResponseDTO extends Serializable {
  @jsonProperty(Number)
  Id!: number

  @jsonProperty(String)
  Erro!: string

  @jsonProperty(Array)
  Veiculos!: Array<SalesVehicleDTO>

  @jsonProperty(Number)
  QtdVeiculos!: number
}

/**
 * SalesVehicleDataDTO
 */
class VehicleDataDTO extends Serializable {
  @jsonProperty(Array)
  Response!: Array<SalesVehicleResponseDTO>
}

/**
 * RentalDetailsDataDTO
 */
export class InvoiceResponseDTO extends Serializable {
  @jsonProperty(Number)
  Id!: number

  @jsonProperty(String)
  Erro!: string

  @jsonProperty(Array)
  Faturas!: Array<SalesInvoiceDTO>
}

/**
 * InvoiceDataDTO
 */
export class InvoiceDataDTO extends Serializable {
  @jsonProperty(InvoiceResponseDTO)
  Response!: InvoiceResponseDTO
}

/**
 * RentalDetailsFromPaginationDTO
 */
export class RentalDetailsFromPaginationDTO extends Serializable {
  @jsonProperty(Boolean)
  hasMonthlyBillFile!: boolean

  @jsonProperty(Boolean)
  hasAdditionalFile!: boolean

  @jsonProperty(Number)
  document!: number

  @jsonProperty(InvoiceDataDTO)
  rentalInvoices!: InvoiceDataDTO

  @jsonProperty(VehicleDataDTO)
  rentalVehicles!: VehicleDataDTO

  @jsonProperty(Array)
  contractsListDetails!: Array<RentalContractDTO>

  @jsonProperty(String)
  productType!: string
}

/**
 * Contract pagination model
 */
class ContractPaginationDTO extends Serializable {
  @jsonProperty(Number)
  totalContracts!: number

  @jsonProperty(Array)
  contracts!: Array<ContractDTO>

  @jsonProperty(Array)
  rentalDetails!: Array<RentalDetailsFromPaginationDTO>

  @jsonProperty(Number)
  pageNumberFilter!: number
}

export default ContractPaginationDTO
