import { useQuery } from 'react-query'
import { HookQueryParams } from '../../../common/utils/types'
import ContractInstallmentDTO from '../../../models/ContractInstallmentDTO'
import { payInstallmentsByContract } from '../../actions/Finance'

type usePayInstallmentsByContractParams =
  HookQueryParams<ContractInstallmentDTO> & {
    contractNumber: string
    dueDate: Date
    financeType: string
    documentNumber: string
    installmentsToPay?: Array<number>
    sourceResources?: string
    reasonToLiquidate?: string
  }

/**
 * @param params params
 * @returns methods
 */
export const usePayInstallmentsByContract = (
  params: usePayInstallmentsByContractParams
) => {
  const {
    retry,
    suspense,
    cacheTime,
    contractNumber,
    dueDate,
    financeType,
    documentNumber,
    installmentsToPay,
    sourceResources,
    reasonToLiquidate,
    enabled,
    onSuccess,
    onError
  } = params

  const { data, isFetching, isLoading, isFetched, refetch, error } = useQuery({
    queryKey: [
      'payInstallmentsByContract',
      contractNumber,
      dueDate,
      financeType,
      documentNumber,
      installmentsToPay,
      sourceResources,
      reasonToLiquidate
    ],
    queryFn: () => {
      return payInstallmentsByContract(
        contractNumber,
        dueDate,
        financeType,
        documentNumber,
        installmentsToPay,
        sourceResources,
        reasonToLiquidate
      )
    },
    retry,
    suspense,
    cacheTime,
    enabled,
    onError,
    onSuccess
  })

  return {
    data,
    isFetching,
    isLoading,
    isFetched,
    refetch,
    error
  }
}
