import { NpsOriginEnum } from '@volvo-apps/shared/common/utils/enums'
import { downloadExtractPdf } from '@volvo-apps/shared/core/actions/Finance'
import { useGetInstallmentsByContract } from '@volvo-apps/shared/core/hooks/contracts'
import ContractDTO from '@volvo-apps/shared/models/ContractDTO'
import InstallmentRequestDTO from '@volvo-apps/shared/models/InstallmentRequestDTO'

import React, { useEffect } from 'react'

import { Typography } from '@mui/material'
import { useMutation } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'

import AdvancePaymentButton from 'components/AdvancePaymentButton/AdvancePaymentButton'
import { ContractCard } from 'components/ContractCard'
import ContractContentContainer from 'components/ContractContentContainer/ContractContentContainer'
// import DischargeContractButton from 'components/DischargeContractButton/DischargeContractButton'
import { FlexBox } from 'components/FlexBox'
import { NoInfo } from 'components/NoInfo'
import SavePdfButton from 'components/SavePdfButton/SavePdfButton'

import { FinancialRoutesEnum } from 'routes/financial.routes'

import Colors from 'constants/Colors'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

import { TicketsAccordion } from '../Tickets/components'
import AdvancePaymentModal from './ContractDetailModals/AdvancePaymentModal/AdvancePaymentModal'
// import DischargeContractModal from './ContractDetailModals/DischargeContractModal/DischargeContractModal'
import { DuplicateType } from './ContractDetailModals/duplicateModal'

type ButtonClickedTypeProps = DuplicateType | undefined

/**
 * @returns TSX
 */
function ContractDetail() {
  // const [openDischargeModal, setOpenDischargeModal] = React.useState(false)
  const [openAdvancePaymentModal, setOpenAdvancePaymentModal] =
    React.useState(false)
  const { t } = useLanguage()

  const [latestRequest, setLatestRequest] = React.useState<
    InstallmentRequestDTO | undefined
  >()
  // const [openDuplicateModal, setOpenDuplicateModal] = React.useState(false)
  const [buttonClickedType, setButtonClickedType] =
    React.useState<ButtonClickedTypeProps>()

  // const handleClose = () => {
  //   setButtonClickedType(undefined)
  //   setOpenDischargeModal(false)
  // }
  const handleCloseModal = () => {
    setButtonClickedType(undefined)
    setOpenAdvancePaymentModal(false)
  }
  const locationState = useLocation().state as { contract?: ContractDTO }
  const navigate = useNavigate()

  const contract = locationState?.contract

  // const handleModalToOpen = () => {
  // if (buttonClickedType === 'advance')
  // setOpenAdvancePaymentModal(true)
  // else setOpenDischargeModal(true)
  // }

  const { refetch, isFetching: getInstallmentsFetching } =
    useGetInstallmentsByContract({
      contractNumber: contract?.numeroContrato ?? '',
      documentNumber: contract?.cpfCnpj ?? '',
      retry: 0,
      enabled: false,
      cacheTime: 0,
      onSuccess: (data) => {
        const cloneRequests = [...data.onGoingRequests]
        const latestRequest = cloneRequests
          .reverse()
          .find((request) =>
            buttonClickedType === 'advance'
              ? !request.isContractAcquittance
              : request.isContractAcquittance
          )

        setLatestRequest(latestRequest)
        setOpenAdvancePaymentModal(true)
        // if (latestRequest) {
        //   setOpenDuplicateModal(true)
        // } else {
        //   handleModalToOpen()
        // }
      },
      onError: handleErrorFeedback
    })

  const { mutate, isLoading } = useMutation(
    ['downloadExtractPdf', contract?.numeroContrato],
    downloadExtractPdf
  )

  const downloadPDF = () => {
    if (!contract?.numeroContrato) return
    mutate(contract?.numeroContrato)
  }

  useEffect(() => {
    if (!locationState?.contract) {
      navigate(FinancialRoutesEnum.CONTRACTS)
    }
  }, [locationState, navigate])

  if (!locationState?.contract) {
    return null
  }

  const contractState = locationState.contract

  const cardStyle = {
    border: `1px solid ${Colors.light.greys.GREY_200}`,
    boxShadow: `1px 1px 5px ${Colors.light.shadow}`,
    width: '100%',
    p: 2,
    borderRadius: 2,
    flexGrow: 1
  }

  const iconStyle = {
    marginRight: -8, // revert startIcon span margin
    width: '32px',
    height: '32px'
  }

  return (
    <ContractContentContainer
      title={t('financial.menu.contracts').toUpperCase()}
    >
      {contractState.cedido || !contractState.isValid ? (
        <NoInfo />
      ) : (
        <>
          <FlexBox gap={3} flexDirection="row">
            <FlexBox
              {...cardStyle}
              flexGrow={0}
              alignItems="center"
              width="100px"
              height="100px"
              sx={{ backgroundColor: Colors.light.tint }}
            >
              <SavePdfButton
                iconStyle={iconStyle}
                label={t('financial.contracts.saveExtract')}
                loading={isLoading}
                onClick={downloadPDF}
                style={{
                  paddingTop: 1,
                  paddingBottom: 1,
                  marginBottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  width: 100,
                  color: Colors.light.background,
                  lineHeight: 1
                }}
                sx={{
                  flexDirection: 'column',
                  gap: 1
                }}
              />
            </FlexBox>
            {/* {openDuplicateModal && latestRequest && (
              <DuplicateModal
                open={openDuplicateModal}
                type={buttonClickedType}
                latestRequest={latestRequest}
                contract={contractState}
                handleDownloadDuplicate={() => {
                  setOpenDuplicateModal(false)
                }}
                handleClose={() => {
                  setOpenDuplicateModal(false)
                }}
                handleNew={() => {
                  setOpenDuplicateModal(false)
                  handleModalToOpen()
                }}
              />
            )} */}

            {contractState.canAntecipate && (
              <FlexBox
                {...cardStyle}
                flexGrow={0}
                alignItems="center"
                width="100px"
                height="100px"
                sx={{ backgroundColor: Colors.light.tint }}
              >
                <AdvancePaymentButton
                  iconStyle={iconStyle}
                  loading={
                    getInstallmentsFetching
                    // && buttonClickedType === 'advance'
                  }
                  label={t('financial.contracts.advancePayment')}
                  onClick={() => {
                    // setButtonClickedType('advance')
                    // handleModalToOpen()
                    refetch()
                  }}
                />
              </FlexBox>
            )}
            {openAdvancePaymentModal && (
              <AdvancePaymentModal
                open={openAdvancePaymentModal}
                handleClose={handleCloseModal}
                hasRecentAgreements={!!latestRequest}
                contractNumber={contractState.numeroContrato}
                financeType={contractState.tipoDeProduto}
                documentNumber={contractState.cpfCnpj}
              />
            )}
            {/* <FlexBox
              {...cardStyle}
              flexGrow={0}
              alignItems="center"
              width="100px"
              height="100px"
              sx={{ backgroundColor: Colors.light.tint }}
            >
              <DischargeContractButton
                iconStyle={iconStyle}
                loading={
                  getInstallmentsFetching && buttonClickedType === 'discharge'
                }
                label={t('financial.contracts.dischargeContract')}
                onClick={() => {
                  setButtonClickedType('discharge')
                  refetch()
                }}
              />
              {openDischargeModal && (
                <DischargeContractModal
                  open={openDischargeModal}
                  hasRecentAgreements={!!latestRequest}
                  handleClose={handleClose}
                  contractNumber={contractState.numeroContrato}
                  financeType={contractState.tipoDeProduto}
                  documentNumber={contractState.cpfCnpj}
                />
              )}
            </FlexBox> */}
          </FlexBox>
          <FlexBox gap={4} pt={2}>
            <FlexBox flexDirection="column" width={'100%'}>
              <Typography
                fontSize={16}
                color={Colors.light.interactActive}
                my={3}
                fontWeight={700}
              >
                {t('financial.contracts.contractDetail')}
              </Typography>
              <ContractCard
                contract={locationState.contract}
                boxProps={cardStyle}
                showMoreDetails
              />
            </FlexBox>
          </FlexBox>

          <Typography
            fontSize={16}
            color={Colors.light.interactActive}
            my={4}
            fontWeight={700}
          >
            {t('financial.menu.tickets').toUpperCase()}
          </Typography>

          <TicketsAccordion
            key={contractState.numeroContrato}
            contract={contractState}
            npsOrigin={NpsOriginEnum.SHARE_TICKET_IN_CONTRACT}
          />
        </>
      )}
    </ContractContentContainer>
  )
}

export default ContractDetail
export { ContractDetail }
