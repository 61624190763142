import { makeResolver } from 'common/utils/form'

import { dueDateValidation, holidayMapProps } from '../dateSchema'

/**
 *
 */
export type AdvancePaymentModalData = {
  /**
   *
   */
  dueDate: Date
}

/**
 * Generates a validation schema for the due date.
 *
 * @param holidayList - List of holidays to consider in the date validation
 * @returns schema - The generated validation schema
 */
export const getSchema = (holidayList?: holidayMapProps) => {
  const schema = makeResolver((Yup) =>
    Yup.object({
      dueDate: dueDateValidation(Yup, holidayList || {})
    })
  )

  return schema
}
