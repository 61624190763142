import { useQuery } from 'react-query'
import { HookQueryParams } from '../../../common/utils/types'
import ContractInstallmentDTO from '../../../models/ContractInstallmentDTO'
import { getAllInstallmentsByContract } from '../../actions/Finance'

type useGetInstallmentsByContractParams =
  HookQueryParams<ContractInstallmentDTO> & {
    contractNumber: string
    documentNumber: string
  }

/**
 * @param params params
 * @returns methods
 */
export const useGetInstallmentsByContract = (
  params: useGetInstallmentsByContractParams
) => {
  const {
    retry,
    suspense,
    cacheTime,
    contractNumber,
    documentNumber,
    onSuccess,
    onError,
    enabled
  } = params

  const { data, isFetching, isLoading, isFetched, refetch } = useQuery({
    queryKey: ['getAllInstallmentsByContract', contractNumber, documentNumber],
    queryFn: () => getAllInstallmentsByContract(contractNumber, documentNumber),
    retry,
    suspense,
    enabled,
    cacheTime,
    onSuccess,
    onError
  })

  return {
    data,
    isFetching,
    isLoading,
    isFetched,
    refetch
  }
}
