import { useGetPaginatedContracts } from '@volvo-apps/shared/core/hooks/contracts'
import ContractDTO from '@volvo-apps/shared/models/ContractDTO'
import { RentalDetailsFromPaginationDTO } from '@volvo-apps/shared/models/ContractPaginationDTO'
import RentalContractDTO from '@volvo-apps/shared/models/RentalContractDTO'

import React, { useMemo } from 'react'

import { useNavigate } from 'react-router-dom'

import { Link, Title } from 'components'
import { Carousel } from 'components/Carousel'
import { ContractCard } from 'components/ContractCard'
import { ContractStatusChip } from 'components/ContractStatusChip'
import { FlexBox } from 'components/FlexBox'
import { Loader } from 'components/Loader'
import { RentalContractCard } from 'components/RentalContractCard'

import { FinancialRoutesEnum } from 'routes/financial.routes'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
function Contracts() {
  const { data, isLoading, isFetching } = useGetPaginatedContracts({
    retry: 0,
    suspense: false,
    cacheTime: 0
  })

  const navigate = useNavigate()
  const { t } = useLanguage()

  const handleClick = React.useCallback(
    (contract: ContractDTO) => {
      navigate(
        FinancialRoutesEnum.CONTRACT_DETAIL.replace(
          ':id',
          contract.numeroContrato
        ),
        {
          state: { contract }
        }
      )
    },
    [navigate]
  )

  const handleRentalClick = React.useCallback(
    (rentalContract: RentalContractDTO) => {
      navigate(
        FinancialRoutesEnum.RENTAL_DETAIL.replace(
          ':id',
          rentalContract.documento?.toString() ?? ''
        ),
        { state: { rentalContract } }
      )
    },
    [navigate]
  )

  const isRental = (
    contract: ContractDTO | RentalDetailsFromPaginationDTO
  ): contract is RentalDetailsFromPaginationDTO => {
    return !(contract as ContractDTO).codEmpresa
  }

  const makeChildrenComponent = React.useCallback(
    (contract: ContractDTO | RentalDetailsFromPaginationDTO) => {
      if (!contract) return null

      if (isRental(contract)) {
        const contractDetails =
          contract.contractsListDetails &&
          contract.contractsListDetails.length > 0
            ? contract.contractsListDetails[0]
            : null

        const item = new RentalContractDTO().fromJSON({
          numeroContrato: Number(contractDetails?.numeroContrato ?? 0),
          documento: contractDetails?.documento ?? '',
          vigenciaContrato: contractDetails?.vigenciaContrato ?? new Date(),
          nomeRazao: contractDetails?.nomeRazao ?? '',
          isValid: contractDetails?.isValid ?? false,
          dataContrato: contractDetails?.dataContrato,
          qtdVeiculos: contractDetails?.qtdVeiculos ?? 0,
          productType: contract?.productType ?? ''
        })

        return (
          <FlexBox
            sx={{
              width: '100%',
              justifyContent: 'center'
            }}
          >
            <RentalContractCard
              rentalContract={item}
              boxProps={{
                sx: {
                  py: 1.5,
                  px: 1,
                  pb: 6.5,
                  width: 'calc(100% - 48px)',
                  cursor: 'pointer'
                },
                border: `1px solid ${Colors.light.greys.GREY_200}`,
                boxShadow: `1px 1px 5px ${Colors.light.shadow}`,
                borderRadius: '4px',
                onClick: () => handleRentalClick(item)
              }}
              fromContractsScreen
            />
          </FlexBox>
        )
      }

      return (
        <FlexBox
          sx={{
            width: '100%',
            justifyContent: 'center'
          }}
        >
          <ContractCard
            contract={contract}
            boxProps={{
              sx: {
                py: 1.5,
                px: 1,
                width: 'calc(100% - 48px)',
                cursor: 'pointer'
              },
              border: `1px solid ${Colors.light.greys.GREY_200}`,
              boxShadow: `1px 1px 5px ${Colors.light.shadow}`,
              borderRadius: '4px',
              onClick: () => handleClick(contract)
            }}
            titlePrefix={
              <ContractStatusChip
                contract={contract}
                sx={{
                  padding: '0px 20px !important',
                  height: 24,
                  m: 0
                }}
              />
            }
            titleSx={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 1
            }}
          />
        </FlexBox>
      )
    },
    [handleClick, handleRentalClick]
  )

  const items = useMemo(() => {
    const hasPages = data && data.pages.length > 0

    if (!hasPages) return []

    const page = data.pages[0]
    const contracts = page.contracts || []
    const rentalDetails = page.rentalDetails || []

    const allContracts = [...contracts, ...rentalDetails]

    return allContracts.map(makeChildrenComponent)
  }, [data, makeChildrenComponent])

  if (isLoading || isFetching) {
    return (
      <FlexBox>
        <Loader size={16} />
      </FlexBox>
    )
  }

  return items.length > 0 ? (
    <FlexBox flexDirection="column">
      <Title variant="h6" fontSize={20} mb={3}>
        {t('dashboard.yourContracts')}
      </Title>
      <FlexBox sx={{ mb: 1.5 }}>
        <Carousel
          items={items}
          arrowSize={48}
          disableDotsControls
          responsive={{
            0: { items: 3 }
          }}
        />
      </FlexBox>

      <Link
        to={FinancialRoutesEnum.CONTRACTS}
        alignSelf="end"
        underline="always"
        color={Colors.light.interactActive}
      >
        {t('dashboard.showMore')}
      </Link>
    </FlexBox>
  ) : null
}

export default Contracts
export { Contracts }
