import React from 'react'

import { Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import dayjs from 'dayjs'

import { toCurrency } from 'common/utils/money'
import { useLanguage } from 'context/Language'

/**
 * @param totalInstallments the total installments of the contract
 * @returns TSX
 */
export const getColumns = (totalInstallments?: number): GridColDef[] => [
  {
    field: 'installmentNo',
    headerName: 'financial.contracts.modal.columns.parcel',
    width: 250,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const installmentNo = params.row.installmentNo as number

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { t } = useLanguage()

      return (
        <Typography fontSize={16}>
          {installmentNo} {t('financial.contracts.modal.columns.from')}{' '}
          {totalInstallments}
        </Typography>
      )
    }
  },
  {
    field: 'dueDate',
    headerName: 'financial.contracts.modal.columns.endDate',
    width: 270,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const dueDate = params.row.dueDate as Date

      return (
        <Typography fontSize={16}>
          {dayjs(dueDate).format('DD/MM/YYYY')}
        </Typography>
      )
    }
  },
  {
    field: 'installmentCurrentValue',
    headerName: 'financial.contracts.modal.columns.parcelValue',
    width: 150,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const installmentValue = params.row.installmentValue as number

      return (
        <Typography fontSize={16}>
          {toCurrency(installmentValue).withSymbol}
        </Typography>
      )
    }
  }
]
