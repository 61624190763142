import dayjs from 'dayjs'

import { getDateKeyIgnoringYearAndTimezone } from 'common/utils/date'

/**
 *
 */
export type holidayMapProps = {
  [key: string]: boolean
}

/**
 * Validates the due date.
 *
 * @param Yup - The Yup validation library.
 * @param holidayMap - A map of holidays where the key is the date and the value is a boolean indicating if it's a holiday.
 * @returns A Yup date schema with custom validation rules.
 */
export const dueDateValidation = (Yup, holidayMap: holidayMapProps) =>
  Yup.date()
    .typeError('validation.invalid.date')
    .required('validation.required.general')
    .test('is-after-current-date', 'validation.date.afterCurrent', (value) =>
      dayjs(value).isAfter(dayjs().subtract(1, 'day'))
    )
    .test('is-not-holiday', 'validation.date.notHoliday', (value) => {
      const dateKey = getDateKeyIgnoringYearAndTimezone(new Date(value))
      return !holidayMap[dateKey]
    })
    .test('is-not-weekend', 'validation.date.notWeekend', (value) => {
      const day = new Date(value).getDay()
      return day !== 0 && day !== 6
    })
