import { useQuery } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import { getImportBlockedContractsHistory } from '../../actions/Finance'
import BlockedContractImportHistoryDTO from '../../../models/BlockedContractImportHistoryDTO'

type useGetImportBlockedContractsHistoryParams = HookQueryParams<
  BlockedContractImportHistoryDTO[]
>

/**
 * Hook to get all import blocked contracts history
 *
 * @param params params
 * @returns history, isLoading, refetch
 */
export const useGetImportBlockedContractsHistory = (
  params: useGetImportBlockedContractsHistoryParams = {}
) => {
  const { onSuccess, onError } = params
  const {
    data: history,
    isLoading,
    refetch
  } = useQuery('importBlockedContractsHistory', {
    queryFn: getImportBlockedContractsHistory,
    onSuccess,
    onError
  })

  return { history, isLoading, refetch }
}
