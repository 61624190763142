import React from 'react'

import { PlainButton, PlainButtonProps } from 'components/PlainButton'

import Colors from 'constants/Colors'

import { ReactComponent as AdvancePayment } from '../../assets/icons/advancePayment.svg'

type AdvancePaymentButtonProps = {
  label: string
  iconStyle?: React.CSSProperties
} & PlainButtonProps

/**
 *
 * @param props AdvancePaymentButtonProps
 * @returns TSX
 */
const AdvancePaymentButton = (props: AdvancePaymentButtonProps) => {
  const { label, iconStyle, ...plainButtonProps } = props

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    plainButtonProps.onClick?.(e)
  }

  return (
    <PlainButton
      startIcon={<AdvancePayment style={iconStyle} />}
      {...plainButtonProps}
      onClick={handleClick}
      style={{
        paddingTop: 1,
        paddingBottom: 1,
        marginBottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: 100,
        color: Colors.light.background,
        lineHeight: 1
      }}
      sx={{
        flexDirection: 'column',
        gap: 1
      }}
    >
      {label}
    </PlainButton>
  )
}

export default AdvancePaymentButton
