import React from 'react'

import { CustomRouteProps } from 'components/Project/CustomRoute/types'

import {
  List as BannersList,
  Create as BannersCreate,
  Update as BannersUpdate
} from 'pages/cms/Registers/Banners'
import {
  List as ConsortiumResourcesList,
  Create as ConsortiumResourcesCreate
} from 'pages/cms/Registers/ConsortiumResources'
import {
  List as FaqCategoriesList,
  Create as FaqCategoriesCreate,
  Update as FaqCategoriesUpdate
} from 'pages/cms/Registers/Faq/Categories'
import { Menu as FaqMenu } from 'pages/cms/Registers/Faq/Menu'
import {
  List as FaqQuestionsList,
  Create as FaqQuestionsCreate,
  Update as FaqQuestionsUpdate
} from 'pages/cms/Registers/Faq/Questions'
import {
  List as HolidaysList,
  Create as HolidaysCreate,
  Update as HolidaysUpdate
} from 'pages/cms/Registers/Holidays'
import {
  List as ManualBlockList,
  Create as ManualBlockCreate
} from 'pages/cms/Registers/ManualBlock'
import { Menu as RegistersMenu } from 'pages/cms/Registers/Menu'
import {
  List as PushNotificationsList,
  Create as PushNotificationsCreate,
  Update as PushNotificationsUpdate
} from 'pages/cms/Registers/PushNotifications'
import {
  List as RentalContractsList,
  Create as RentalContractsCreate
} from 'pages/cms/Registers/RentalContracts'
import {
  List as TermsList,
  Create as TermsCreate,
  Update as TermsUpdate
} from 'pages/cms/Registers/Terms'
import { CmsContent } from 'pages/layout/CmsContent'
import { RoleEnum } from 'types/roles'

import { RegistersRoutesEnum } from './routesEnums'

const registersRoutes: CustomRouteProps[] = [
  {
    path: RegistersRoutesEnum.REGISTERS,
    protected: true,
    layout: CmsContent,
    element: <RegistersMenu />
  },
  {
    path: RegistersRoutesEnum.BANNERS,
    protected: true,
    layout: CmsContent,
    element: <BannersList />,
    role: RoleEnum.CADASTRO_BANNERS
  },
  {
    path: RegistersRoutesEnum.BANNERS_CREATE,
    protected: true,
    layout: CmsContent,
    element: <BannersCreate />,
    role: RoleEnum.CADASTRO_BANNERS
  },
  {
    path: RegistersRoutesEnum.BANNERS_UPDATE,
    protected: true,
    layout: CmsContent,
    element: <BannersUpdate />,
    role: RoleEnum.CADASTRO_BANNERS
  },
  {
    path: RegistersRoutesEnum.FAQ,
    protected: true,
    layout: CmsContent,
    element: <FaqMenu />,
    role: RoleEnum.CADASTRO_FAQ
  },
  {
    path: RegistersRoutesEnum.FAQ_CATEGORIES_CREATE,
    protected: true,
    layout: CmsContent,
    element: <FaqCategoriesCreate />,
    role: RoleEnum.CADASTRO_FAQ
  },
  {
    path: RegistersRoutesEnum.FAQ_CATEGORIES_UPDATE,
    protected: true,
    layout: CmsContent,
    element: <FaqCategoriesUpdate />,
    role: RoleEnum.CADASTRO_FAQ
  },
  {
    path: RegistersRoutesEnum.FAQ_CATEGORIES_VIEW,
    protected: true,
    layout: CmsContent,
    element: <FaqCategoriesList />,
    role: RoleEnum.CADASTRO_FAQ
  },
  {
    path: RegistersRoutesEnum.FAQ_QUESTIONS_VIEW,
    protected: true,
    layout: CmsContent,
    element: <FaqQuestionsList />,
    role: RoleEnum.CADASTRO_FAQ
  },
  {
    path: RegistersRoutesEnum.FAQ_QUESTIONS_CREATE,
    protected: true,
    layout: CmsContent,
    element: <FaqQuestionsCreate />,
    role: RoleEnum.CADASTRO_FAQ
  },
  {
    path: RegistersRoutesEnum.FAQ_QUESTIONS_UPDATE,
    protected: true,
    layout: CmsContent,
    element: <FaqQuestionsUpdate />,
    role: RoleEnum.CADASTRO_FAQ
  },
  {
    path: RegistersRoutesEnum.HOLIDAYS,
    protected: true,
    layout: CmsContent,
    element: <HolidaysList />,
    role: RoleEnum.CADASTRO_FERIADOS
  },
  {
    path: RegistersRoutesEnum.HOLIDAYS_CREATE,
    protected: true,
    layout: CmsContent,
    element: <HolidaysCreate />,
    role: RoleEnum.CADASTRO_FERIADOS
  },
  {
    path: RegistersRoutesEnum.HOLIDAYS_UPDATE,
    protected: true,
    layout: CmsContent,
    element: <HolidaysUpdate />,
    role: RoleEnum.CADASTRO_FERIADOS
  },
  {
    path: RegistersRoutesEnum.PUSH_NOTIFICATIONS,
    protected: true,
    layout: CmsContent,
    element: <PushNotificationsList />,
    role: RoleEnum.CADASTRO_NOTIFICACOES_PUSH
  },
  {
    path: RegistersRoutesEnum.PUSH_NOTIFICATIONS_CREATE,
    protected: true,
    layout: CmsContent,
    element: <PushNotificationsCreate />,
    role: RoleEnum.CADASTRO_NOTIFICACOES_PUSH
  },
  {
    path: RegistersRoutesEnum.PUSH_NOTIFICATIONS_UPDATE,
    protected: true,
    layout: CmsContent,
    element: <PushNotificationsUpdate />,
    role: RoleEnum.CADASTRO_NOTIFICACOES_PUSH
  },
  {
    path: RegistersRoutesEnum.TERMS,
    protected: true,
    layout: CmsContent,
    element: <TermsList />,
    role: RoleEnum.CADASTRO_TERMOS
  },
  {
    path: RegistersRoutesEnum.TERMS_CREATE,
    protected: true,
    layout: CmsContent,
    element: <TermsCreate />,
    role: RoleEnum.CADASTRO_TERMOS
  },
  {
    path: RegistersRoutesEnum.TERMS_UPDATE,
    protected: true,
    layout: CmsContent,
    element: <TermsUpdate />,
    role: RoleEnum.CADASTRO_TERMOS
  },
  {
    path: RegistersRoutesEnum.CONSORTIUM_RESOURCES,
    protected: true,
    layout: CmsContent,
    element: <ConsortiumResourcesList />,
    role: RoleEnum.CADASTRO_RECURSOS_ENCERRADOS
  },
  {
    path: RegistersRoutesEnum.CONSORTIUM_RESOURCES_CREATE,
    protected: true,
    layout: CmsContent,
    element: <ConsortiumResourcesCreate />,
    role: RoleEnum.CADASTRO_RECURSOS_ENCERRADOS
  },
  {
    path: RegistersRoutesEnum.RENTAL_CONTRACTS,
    protected: true,
    layout: CmsContent,
    element: <RentalContractsList />,
    role: RoleEnum.CADASTRO_CONTRATOS_ALUGUEL
  },
  {
    path: RegistersRoutesEnum.RENTAL_CONTRACTS_CREATE,
    protected: true,
    layout: CmsContent,
    element: <RentalContractsCreate />,
    role: RoleEnum.CADASTRO_CONTRATOS_ALUGUEL
  },
  {
    path: RegistersRoutesEnum.MANUAL_BLOCK,
    protected: true,
    layout: CmsContent,
    element: <ManualBlockList />,
    role: RoleEnum.BLOQUEIO_CONTRATO
  },
  {
    path: RegistersRoutesEnum.MANUAL_BLOCK_CREATE,
    protected: true,
    layout: CmsContent,
    element: <ManualBlockCreate />,
    role: RoleEnum.BLOQUEIO_CONTRATO
  }
]

export default registersRoutes
