import React from 'react'

import { Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'

import { toCurrency } from 'common/utils/money'
import { useLanguage } from 'context/Language'

/**
 *
 * @returns TSX
 */
export const totalPreviewColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: '',
    sortable: false,
    flex: 1,
    renderCell: () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { t } = useLanguage()

      return (
        <Typography fontSize={16}>
          {t('financial.contracts.modal.parcelsTotalValue')}
        </Typography>
      )
    }
  },
  {
    field: 'total',
    headerName: '',
    flex: 1,
    sortable: false,
    align: 'right',
    renderCell: (params: GridRenderCellParams) => {
      const total = params.row.total as number

      return (
        <Typography fontSize={16}>{toCurrency(total).withSymbol}</Typography>
      )
    }
  }
]
