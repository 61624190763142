import { useQuery } from 'react-query'
import { getConsortium } from '../actions/Consortium'
import { HookQueryParams } from '../../common/utils/types'

type useGetConsortiumParams = HookQueryParams<boolean>

/**
 * Hook to get consortium
 *
 * @param params params
 * @returns data, isLoading
 */
export const useGetConsortium = (params: useGetConsortiumParams = {}) => {
  const { onSuccess, suspense } = params

  const { data, isLoading } = useQuery('getConsortium', {
    queryFn: getConsortium,
    onSuccess,
    suspense
  })

  return {
    data,
    isLoading
  }
}
