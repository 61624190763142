import React from 'react'

import ContentContainer from 'components/ContentContainer/ContentContainer'
import { EmbeddedMessaging } from 'components/EmbeddedMessaging'

import { Actions } from './Actions'
import { Alerts } from './Alerts'
import { Contracts } from './Contracts'
import { Modals } from './Modals'
import { News } from './News'

/**
 * @returns TSX
 */
function Dashboard() {
  return (
    <ContentContainer>
      <EmbeddedMessaging />
      <Alerts />
      <Contracts />
      <Actions />
      <News />
      <Modals />
    </ContentContainer>
  )
}

export default Dashboard
export { Dashboard }
