import React from 'react'

import { Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'

import { toCurrency } from 'common/utils/money'

/**
 *
 * @returns TSX
 */
export const subtotalColumns: GridColDef[] = [
  {
    field: 'subtotal',
    headerName: 'financial.contracts.modal.columns.parcelTotal',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const subtotal = params.row.subtotal as number

      return (
        <Typography fontSize={16}>{toCurrency(subtotal).withSymbol}</Typography>
      )
    }
  },
  {
    field: 'discount',
    headerName: 'financial.contracts.modal.columns.discountValue',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const discount = params.row.discount as number

      return (
        <Typography fontSize={16}>{toCurrency(discount).withSymbol}</Typography>
      )
    }
  },
  {
    field: 'total',
    headerName: 'financial.contracts.modal.columns.currentTotalValue',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const total = params.row.total as number

      return (
        <Typography fontSize={16}>{toCurrency(total).withSymbol}</Typography>
      )
    }
  }
]
