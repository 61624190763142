import React, { useEffect } from 'react'
/**
 *
 */
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    embeddedservice_bootstrap: any
  }
}
/**
 * Componente para carregar o Embedded Messaging
 *
 * @returns TSX
 */
export const EmbeddedMessaging = () => {
  useEffect(() => {
    const initEmbeddedMessaging = () => {
      try {
        window.embeddedservice_bootstrap.settings.language = 'en_US' // Por exemplo, 'en' ou 'en-US'
        window.embeddedservice_bootstrap.init(
          '00DKK000000Gycr',
          'VFSBR_Messaging_Web_Embedded_Service',
          'https://vfs-emea--uat.sandbox.my.site.com/ESWVFSBRMessagingWebEm1725055750069',
          {
            scrt2URL: 'https://vfs-emea--uat.sandbox.my.salesforce-scrt.com'
          }
        )
      } catch (err) {
        console.error('Error loading Embedded Messaging: ', err)
      }
    }
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src =
      'https://vfs-emea--uat.sandbox.my.site.com/ESWVFSBRMessagingWebEm1725055750069/assets/js/bootstrap.min.js'
    script.onload = initEmbeddedMessaging
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])
  return <div id="khipu-web-root"></div>
}
export default EmbeddedMessaging
