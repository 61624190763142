import { download, blobToURL } from '@volvo-apps/web/src/common/utils/download'
import ContractDTO from '@volvo-apps/shared/models/ContractDTO'
import { FinanceService } from '../service/Finance.service'
import ContractPaginationDTO from '../../models/ContractPaginationDTO'
import ContractInstallmentDTO from '../../models/ContractInstallmentDTO'
import InstallmentDTO from '../../models/InstallmentDTO'
import PartsAndServicesContractPaginationDTO from '../../models/PartsAndServicesContractPaginationDTO'

/**
 * Get contract by user
 *
 * @param page The page number.
 * @param valueToFilter The value to filter.
 * @param filters The filters.
 * @returns ContractDTO[]
 */
export async function getContractByUser(
  page: number,
  valueToFilter?: string,
  filters: {
    valueToFilter: string
    attributeCodes: number[]
  }[] = []
) {
  const service = new FinanceService()

  const filtersToApply = valueToFilter
    ? [
        {
          valueToFilter,
          attributeCodes: [1, 2, 3, 4, 5, 6]
        },
        ...filters
      ]
    : [...filters]

  const response = await service.getContractByUser(
    page,
    valueToFilter,
    filtersToApply
  )

  return new ContractPaginationDTO().fromJSON({
    ...response.data
  })
}

/**
 * Get contract by user
 *
 * @param page The page number.
 * @param valueToFilter The value to filter.
 * @param filters The filters.
 * @returns ContractDTO[]
 */
export async function getPartsAndServicesContractByUser(
  page: number,
  valueToFilter?: string,
  filters: {
    valueToFilter: string
    attributeCodes: number[]
  }[] = []
) {
  const service = new FinanceService()

  const filtersToApply = valueToFilter
    ? [
        {
          valueToFilter,
          attributeCodes: [1, 2, 3, 4, 5, 6]
        },
        ...filters
      ]
    : [...filters]

  const response = await service.getPartsAndServicesContractByUser(
    page,
    valueToFilter,
    filtersToApply
  )

  return new PartsAndServicesContractPaginationDTO().fromJSON({
    ...response.data
  })
}

/**
 *
 * Download ticket PDF
 *
 * @param contract ContractDTO info
 * @returns void
 */
export async function downloadTicketPDF(contract: ContractDTO) {
  const { numeroContrato, numeroParcela, codSistema, tipoParcela } = contract

  const service = new FinanceService()
  return service
    .getBoletoByContract(numeroContrato, numeroParcela, codSistema, tipoParcela)
    .then(({ data }) => {
      download(
        blobToURL(data),
        'boleto-' +
          contract.numeroContrato +
          '-' +
          contract.numeroParcela +
          '.pdf'
      )
    })
}

/**
 *
 * Download extract PDF
 *
 * @param contractNumber String
 * @returns void
 */
export async function downloadExtractPdf(contractNumber: string) {
  const service = new FinanceService()
  return service.getExtractByContract(contractNumber).then(({ data }) => {
    download(blobToURL(data), 'extrato-' + contractNumber + '.pdf')
  })
}

/**
 *
 * @param contractNumber the number of the contract
 * @param documentNumber the document number
 */
export async function getAllInstallmentsByContract(
  contractNumber: string,
  documentNumber: string
) {
  const service = new FinanceService()
  const response = await service.getInstallmentsByContract(
    contractNumber,
    documentNumber
  )

  return new ContractInstallmentDTO().fromJSON(response.data)
}

/**
 * payInstallmentsByContract
 *
 * @param contractNumber Contract Number
 * @param dueDate Due Date
 * @param financeType Finance Type
 * @param documentNumber Document Number
 * @param installmentsToPay Array of Installment IDs
 * @param sourceResources the source pf the resource
 * @param reasonToLiquidate the reason to liquidate
 * @returns void
 */
export async function payInstallmentsByContract(
  contractNumber: ContractDTO['numeroContrato'],
  dueDate: InstallmentDTO['dueDate'],
  financeType: string,
  documentNumber: string,
  installmentsToPay?: Array<number>,
  sourceResources?: string,
  reasonToLiquidate?: string
) {
  const service = new FinanceService()

  return service
    .payInstallmentsByContract(
      contractNumber,
      dueDate,
      financeType,
      documentNumber,
      installmentsToPay,
      sourceResources,
      reasonToLiquidate
    )
    .then(({ data }) => {
      download(blobToURL(data), 'boleto-' + contractNumber + '.pdf')
    })
}

/**
 *
 * @param formData formData
 */
export async function importFileAsync(formData: FormData) {
  const service = new FinanceService()
  const response = await service.importFile(formData)
  return response.data
}

/**
 *
 */
export async function getImportBlockedContractsHistory() {
  const service = new FinanceService()
  const response = await service.getImportBlockedContractsHistory()
  return response.data
}

/**
 * @param id The id of import blocked contract
 * @returns Delete import blocked contract
 */
export async function deleteImportBlockedContract(id: number) {
  const service = new FinanceService()
  const response = await service.deleteImportBlockedContract(id)

  return response
}
