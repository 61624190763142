import 'reflect-metadata'
import { jsonName, jsonProperty, Serializable } from 'ts-serializable'
import pick from 'lodash/pick'
import mergeWith from 'lodash/mergeWith'
import isArray from 'lodash/isArray'
import { getOnlyNumbers } from '@volvo-apps/shared/common/utils/string'
import DocumentDTO from './DocumentDTO'

/**
 * UserDTO
 */
class UserDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('createdDate')
  @jsonProperty(Date)
  public createdDate?: Date

  @jsonName('modifiedDate')
  @jsonProperty(Date)
  public modifiedDate!: Date

  @jsonName('active')
  @jsonProperty(Boolean)
  public active!: boolean

  @jsonName('name')
  @jsonProperty(String)
  public name?: string

  @jsonName('birthday')
  @jsonProperty(String, Date)
  public birthday!: Date | string

  @jsonName('phoneNumber')
  @jsonProperty(String)
  public phoneNumber?: string

  @jsonName('zipCode')
  @jsonProperty(String)
  public zipCode?: string

  @jsonName('address')
  @jsonProperty(String)
  public address?: string

  @jsonName('addressNumber')
  @jsonProperty(String)
  public addressNumber?: string

  @jsonName('addressComplement')
  @jsonProperty(String, [null, undefined])
  public addressComplement?: string

  @jsonName('city')
  @jsonProperty(String)
  public city?: string

  @jsonName('state')
  @jsonProperty(String)
  public state?: string

  @jsonName('profilePicture')
  @jsonProperty(String, undefined)
  public profilePicture?: string

  @jsonName('email')
  @jsonProperty(String)
  public email?: string

  @jsonName('maskedEmail')
  @jsonProperty(String)
  public maskedEmail?: string

  @jsonName('maskedEmailAutbank')
  @jsonProperty(String)
  public maskedEmailAutbank?: string

  @jsonName('password')
  @jsonProperty(String, undefined)
  public password?: string

  @jsonName('documents')
  @jsonProperty(Array)
  public documents?: Array<DocumentDTO>

  @jsonName('firstAccess')
  @jsonProperty(Boolean)
  public firstAccess!: boolean

  @jsonName('canAccessCms')
  @jsonProperty(Boolean)
  public canAccessCms?: boolean

  @jsonName('fingerprintEnabled')
  @jsonProperty(Boolean)
  public fingerprintEnabled?: boolean

  @jsonName('hasPendingDocuments')
  @jsonProperty(Boolean)
  public hasPendingDocuments?: boolean

  @jsonName('showPasswordResetModal')
  @jsonProperty(Boolean)
  public showPasswordResetModal?: boolean

  @jsonName('resetPasswordTime')
  @jsonProperty(Date, undefined)
  public resetPasswordTime!: Date | undefined

  /**
   *
   * @param data Object
   * @returns new instance
   */
  public fromJSONWithSignUpDocuments(
    data: Record<string, unknown> & { documents: { value: string }[] }
  ) {
    return this.fromJSON({
      ...data,
      documents: data.documents.reduce((acc, curr) => {
        acc.push(
          new DocumentDTO().fromJSON({
            document: getOnlyNumbers(curr.value),
            isSignUpDocument: true,
            isContractsAttached: false,
            verified: false
          })
        )
        return acc
      }, [] as DocumentDTO[])
    })
  }

  /**
   *
   * @param fields keys to return
   * @returns this
   */
  public getAsFormValues(fields: Array<keyof this>) {
    const response = pick(this, fields)

    let signUpDocument: DocumentDTO | undefined

    const documents = response?.documents?.filter((docDTO) => {
      if (!signUpDocument && docDTO.isSignUpDocument) {
        signUpDocument = docDTO
        return false
      }
      return true
    })

    interface FormValues {
      [key: string]: any
    }

    const formValues: FormValues = {
      ...response,
      documents,
      signUpDocument: response?.documents?.find(
        (docDTO) => docDTO.isSignUpDocument
      )
    }

    Object.keys(formValues).forEach((key) => {
      if (formValues[key] === undefined) {
        formValues[key] = ''
      }
    })

    return formValues
  }

  /**
   * @param firstAccess boolean
   *  @returns this
   */
  public updateFirstAccess(firstAccess: boolean) {
    return this.fromJSON({
      ...this,
      firstAccess
    })
  }

  /**
   *
   * @param data Partial this
   * @returns this
   */
  public update(
    data: Partial<Omit<this, 'documents'>> & {
      documents?: {
        document: string
        isSignUpDocument: boolean
        isContractsAttached: boolean
        verified: boolean
      }[]
    }
  ) {
    const newData = {
      ...data,
      documents: data.documents?.reduce((acc, curr) => {
        acc.push(
          new DocumentDTO().fromJSON({
            document: getOnlyNumbers(curr.document),
            isSignUpDocument: curr.isSignUpDocument,
            isContractsAttached: curr.isContractsAttached,
            verified: curr.verified
          })
        )
        return acc
      }, [] as DocumentDTO[])
    }

    const userMerged = mergeWith(this, newData, (_, b) =>
      isArray(b) ? b : undefined
    )

    delete userMerged.createdDate // createdDate is handled by the backend

    return this.fromJSON(userMerged)
  }
}

export default UserDTO
