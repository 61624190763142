import React from 'react'

import { Typography } from '@mui/material'

import { Modal } from 'components'
import { FlexBox } from 'components/FlexBox'
import { NoInfo } from 'components/NoInfo'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

type InvoicesErrorModalProps = {
  open: boolean
  setClosed: () => void
  text: string
}

/**
 * @param props InvoicesErrorModalProps
 * @returns TSX
 */
const InvoicesErrorModal = (props: InvoicesErrorModalProps) => {
  const { open, setClosed, text } = props

  const { t } = useLanguage()

  return (
    <Modal
      showCloseButton
      disableBackdropClick
      title={
        <Typography
          fontSize={22}
          fontFamily="Volvo-Novum-Medium"
          color={Colors.light.error}
        >
          {t('financial.contracts.modal.actionBlocked')}
        </Typography>
      }
      PaperProps={{ sx: { p: 1, borderRadius: 2.5, minWidth: '600px' } }}
      content={
        <FlexBox flexDirection="column">
          <Typography fontSize={16} color={Colors.light.error}>
            {text}
          </Typography>

          <NoInfo hideHeader width="100%" />
        </FlexBox>
      }
      actions={[
        {
          children: t('financial.contracts.modal.closeModal'),
          onClick: () => {
            setClosed()
          },
          style: {
            backgroundColor: Colors.light.interactDefault,
            width: '100%'
          },
          variant: 'contained'
        }
      ]}
      onClose={setClosed}
      open={open}
    />
  )
}

export default InvoicesErrorModal
