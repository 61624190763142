import ContractInstallmentDTO from '@volvo-apps/shared/models/ContractInstallmentDTO'

import React from 'react'

import { Typography } from '@mui/material'

import { Alert } from 'components/Alert'
import { DataGrid } from 'components/DataGrid'
import { FlexBox } from 'components/FlexBox'
import { NoInfo } from 'components/NoInfo'

import Colors from 'constants/Colors'

import { ReactComponent as AlertIcon } from 'assets/icons/alert.svg'
import { useLanguage } from 'context/Language'

import { InstallmentsValues } from './AdvancePaymentModal'
import { parcelsColumns } from './parcelsColumns'
import { subtotalColumns } from './subtotalColumns'

type DiscountModalProps = {
  data?: ContractInstallmentDTO
  selectedInstallments: number[]
  installmentsValues: InstallmentsValues
  isAllInstallmentsSelected: boolean
}

/**
 *
 * @param props - The properties for the component.
 * @returns The rendered component
 */
const DiscountModal = (props: DiscountModalProps): JSX.Element => {
  const { t } = useLanguage()
  const {
    data,
    selectedInstallments,
    installmentsValues,
    isAllInstallmentsSelected
  } = props

  const currentTotalValue = isAllInstallmentsSelected
    ? installmentsValues.currentValue + 1000
    : installmentsValues.currentValue
  return (
    <FlexBox width="100%" flexDirection="column" gap={2}>
      <Alert icon={<AlertIcon />} severity={'warning'} open={true}>
        {t('financial.contracts.modal.alertTax')}
      </Alert>
      <Typography color={Colors.light.greys.GREY_600} mb={1}>
        {t('financial.contracts.modal.selectedParcels')}
      </Typography>
      <DataGrid
        type="darkCells"
        columns={parcelsColumns}
        rows={
          data
            ? [
                {
                  id: 1,
                  parcels: selectedInstallments
                    .sort((a, b) => a - b)
                    .join(', ')
                    .replace(/,([^,]*)$/, ' e$1')
                }
              ]
            : []
        }
        hideFooter
      />
      <DataGrid
        type="darkCells"
        columns={subtotalColumns}
        rows={
          data
            ? [
                {
                  id: 1,
                  subtotal: installmentsValues.value,
                  discount: installmentsValues.discounts,
                  total: currentTotalValue
                }
              ]
            : []
        }
        hideFooter
      />
      <Typography color={Colors.light.greys.GREY_600} mb={1}>
        {t('financial.contracts.modal.continueAntecipation')}
      </Typography>
      <NoInfo hideHeader width="100%" />
    </FlexBox>
  )
}

export default DiscountModal
export { DiscountModal }
