export enum LeadsRoutesEnum {
  LEADS_MENU = '/admin/leads',
  LEADS_VIEW = '/admin/leads/view',
  LEADS_AUTOMATION_LIST = '/admin/leads/automation',
  LEADS_AUTOMATION_CREATE = '/admin/leads/automation/create',
  LEADS_AUTOMATION_UPDATE = '/admin/leads/automation/update/:id'
}

export enum ParametersRoutesEnum {
  PARAMETERS = '/admin/parameters',
  FINANCIAL_PRODUCTS = '/admin/parameters/financial-products',
  FINANCIAL_PRODUCTS_CREATE = '/admin/parameters/financial-products/create',
  FINANCIAL_PRODUCTS_UPDATE = '/admin/parameters/financial-products/update/:id',
  PRODUCT_LINES = '/admin/parameters/product-lines',
  PRODUCT_LINES_CREATE = '/admin/parameters/product-lines/create',
  PRODUCT_LINES_UPDATE = '/admin/parameters/product-lines/update/:id',
  SIMULATION_PARAMS = '/admin/parameters/simulation-parameters',
  SIMULATION_PARAMS_CREATE = '/admin/parameters/simulation-parameters/create',
  SIMULATION_PARAMS_UPDATE = '/admin/parameters/simulation-parameters/update/:id',
  VEHICLES = '/admin/parameters/vehicles',
  VEHICLES_CREATE = '/admin/parameters/vehicles/create',
  VEHICLES_UPDATE = '/admin/parameters/vehicles/update/:id'
}

export enum RegistersRoutesEnum {
  REGISTERS = '/admin/registers',
  BANNERS = '/admin/registers/banners',
  BANNERS_CREATE = '/admin/registers/banners/create',
  BANNERS_UPDATE = '/admin/registers/banners/update/:id',
  FAQ = '/admin/registers/faq',
  FAQ_CATEGORIES_VIEW = '/admin/registers/faq/categories',
  FAQ_CATEGORIES_CREATE = '/admin/registers/faq/categories/create',
  FAQ_CATEGORIES_UPDATE = '/admin/registers/faq/categories/update/:id',
  FAQ_QUESTIONS_VIEW = '/admin/registers/faq/questions',
  FAQ_QUESTIONS_CREATE = '/admin/registers/faq/questions/create',
  FAQ_QUESTIONS_UPDATE = '/admin/registers/faq/questions/update/:id',
  HOLIDAYS = '/admin/registers/holidays',
  HOLIDAYS_CREATE = '/admin/registers/holidays/create',
  HOLIDAYS_UPDATE = '/admin/registers/holidays/update/:id',
  PUSH_NOTIFICATIONS = '/admin/registers/push-notifications',
  PUSH_NOTIFICATIONS_CREATE = '/admin/registers/push-notifications/create',
  PUSH_NOTIFICATIONS_UPDATE = '/admin/registers/push-notifications/update/:id',
  TERMS = '/admin/registers/terms',
  TERMS_CREATE = '/admin/registers/terms/create',
  TERMS_UPDATE = '/admin/registers/terms/update/:id',
  CONSORTIUM_RESOURCES = '/admin/consortium-resources',
  CONSORTIUM_RESOURCES_CREATE = '/admin/consortium-resources/create',
  RENTAL_CONTRACTS = '/admin/rental-contracts',
  RENTAL_CONTRACTS_CREATE = '/admin/rental-contracts/create',
  MANUAL_BLOCK = '/admin/manual-block',
  MANUAL_BLOCK_CREATE = '/admin/manual-block/create'
}

export enum ReportsRoutesEnum {
  REPORTS_MENU = '/admin/reports',
  USER_REPORTS_FORM = '/admin/reports/user-reports',
  USER_SEARCH = '/admin/reports/user-search'
}

export enum SurveyRoutesEnum {
  SURVEY_MENU = '/admin/surveys',
  SURVEY_NPS = '/admin/surveys/nps',
  SURVEY_NPS_CREATE = '/admin/surveys/nps/create',
  SURVEY_NPS_UPDATE = '/admin/surveys/nps/update/:id'
}

export enum UserSettingsRoutesEnum {
  USER_SETTINGS = '/admin/user-settings',
  USERS_ACCESS_LIST = '/admin/user-settings/users-access',
  USERS_ACCESS_LIST_UPDATE = '/admin/user-settings/users-access/update/:id',
  SCR_MENU = '/admin/user-settings/scr',
  SCR_EMAILS = '/admin/user-settings/scr/emails',
  SCR_CLIENTS = '/admin/user-settings/scr/clients',
  LINK_USERS_LIST = '/admin/user-settings/link-users',
  RESET_EMAIL_AND_PASSWORD = '/admin/user-settings/reset-email-and-password',
  ROLES_LIST = '/admin/user-settings/roles',
  ROLES_CREATE = '/admin/roles/user-settings/create',
  ROLES_UPDATE = '/admin/roles/user-settings/update/:id',
  USER_DOMAINS = '/admin/user-settings/functional-domains'
}
